import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '~components/Container';
import CourseCtaButton from '~components/buttons/CourseCtaButton';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: props => props.background_color || 'inherit',
    position: 'relative'
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '0px',
    borderRadius: '20px',
    marginTop: '71px',
    marginBottom: '93px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '30px',
      paddingRight: '30px'
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingLeft: '15px',
      paddingRight: '15px',
      marginTop: '40px'
    }
  },
  title: {
    color: '#000000',
    lineHeight: '115%',
    fontSize: '44px',
    fontWeight: '600',
    fontFamily: 'Inter',
    marginBottom: '50px',
    marginTop: 0,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      marginBottom: '32px'
    }
  },
  buttonContainer: {
    display: 'flex'
  }
}));

const CourseCta = ({
  product_id,
  is_subscriber_eligible,
  is_producer_circle_eligible,
  title,
  background_color,
  cta_text,
  eligible_cta_text,
  eligible_cta_url,
  cta_text_color,
  cta_background_color,
  bottom_shape_divider
}) => {
  const classes = useStyles({
    background_color
  });

  return (
    <div className={classes.root}>
      <Container center>
        <div className={classes.contentArea}>
          <h2 className={classes.title}>{title}</h2>
          <div className={classes.buttonContainer}>
            <CourseCtaButton
              variant="large"
              product_id={product_id}
              is_subscriber_eligible={is_subscriber_eligible}
              is_producer_circle_eligible={is_producer_circle_eligible}
              cta_text={cta_text}
              eligible_cta_text={eligible_cta_text}
              eligible_cta_url={eligible_cta_url}
              cta_text_color={cta_text_color}
              cta_background_color={cta_background_color}
            />
          </div>
        </div>
      </Container>
      {/* Shape Divider */}
      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

CourseCta.propTypes = {
  product_id: PropTypes.string.isRequired,
  is_subscriber_eligible: PropTypes.string.isRequired,
  is_producer_circle_eligible: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cta_text: PropTypes.string.isRequired,
  eligible_cta_text: PropTypes.string.isRequired,
  eligible_cta_url: PropTypes.string.isRequired,
  cta_text_color: PropTypes.string,
  cta_background_color: PropTypes.string,
  background_color: PropTypes.string,
  bottom_shape_divider: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  })
};

CourseCta.defaultProps = {
  background_color: '#FFFFFF',
  cta_text_color: '#FFFFFF',
  cta_background_color: '#000000',
  bottom_shape_divider: null
};

export default CourseCta;
